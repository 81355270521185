import {request, requestOne} from '../index';

//专家接口配置
export function getPage(url, params) {
    return request({
        method: 'GET',
        url: 'aps' + url,
        params: params
    });
}

export function postExpert(url, params) {
    return request({
        method: 'POST',
        url: url,
        params: {
            ...params
        }
    });
}

//第一模块接口
export function getRequestOne(url, params) {
    return requestOne({
        method: 'GET',
        url: url,
        params: params
    });
}
