<template>
  <div class="heaberColor">
    <div class="home">
      <div class="bimg">
        <div class="hd" align="center" style="color: #fff">
          <h2>
            <strong>{{ csgl.zzbz }} {{ csgl.pz }}</strong>
          </h2>

          <img :src=imgUrl class="img-circle img" title="" width="250"/>
          <br/>
          <h4>{{ csgl.qymc }}</h4>

          <h4>溯源码：{{ csgl.qyid }}</h4>
        </div>
        <div class="container-fluid" style="margin-top: 40px">
          <div class="weui_panel">
            <div class="weui_title">
              <img src="@/assets/H5/icon.png" width="34px"/>
              <strong> 基本信息</strong>
            </div>
            <div class="weui_body">
              <div class="weui_cell">
                <div class="weui_left">品种：</div>
                <div class="weui_right">{{ csgl.pz }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">规格：</div>
                <div class="weui_right">{{ csgl.gg }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">采收日期：</div>
                <div class="weui_right">{{ csgl.createTime }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">产地：</div>
                <div class="weui_right">{{ csgl.chandi }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">存储方式：</div>
                <div class="weui_right">{{ csgl.ccfs }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">产品介绍：</div>
                <div class="weui_right">{{ csgl.cpjs }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">种植标准：</div>
                <div class="weui_right strongCol">{{ csgl.zzbz }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">有机种植面积：</div>
                <div class="weui_right">{{ csgl.zzmj }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">年供应量：</div>
                <div class="weui_right">{{ csgl.ngyl }}</div>
              </div>
            </div>
          </div>

          <div class="weui_panel">
            <div class="weui_title">
              <img src="@/assets/H5/icon.png" width="34px"/>
              <span> 检测报告</span>
            </div>
            <div class="weui_foot">
              <p>产品质量安全监管检测报告</p>

              <span @click="viewBtn({ name: '产品检测报告', bs: 'jcbg', type: 'csgl' })">查看 ></span>
            </div>
          </div>

          <div v-if="false" class="weui_panel">
            <div class="weui_title">
              <img src="@/assets/H5/icon.png" width="34px"/>
              <span>溯源档案</span>
            </div>
            <div class="weui_foot">
              <p>产品溯源信息</p>

              <span @click="viewBtn({ name: '溯源信息', type: 'syxx' })">查看 ></span>
            </div>
          </div>

          <div class="weui_panel">
            <div class="weui_title">
              <img src="@/assets/H5/icon.png" width="34px"/>
              <span>采购商信息</span>
            </div>
            <div class="weui_foot">
              <p>采购商信息查询</p>

              <span @click="viewBtn({ name: '采购、经销商信息', type: 'cgsxx' })">查看 ></span>
            </div>
          </div>

          <div class="weui_panel">
            <div class="weui_title">
              <img src="@/assets/H5/icon.png" width="34px"/>
              <span>种植基地信息</span>
            </div>
            <div class="weui_foot">
              <p>基地资料</p>

              <span @click="viewBtn({ name: '基地展示', type: 'jdxx' })">查看 ></span>
            </div>
          </div>

          <div class="weui_panel">
            <div class="weui_title">
              <img src="@/assets/H5/icon.png" width="34px"/>
              <span>企业资料</span>
            </div>
            <img width="100%" :src="`http://public.half-half.cn/${JSON.parse(qyxx.img)[0]}`"/>
            <div class="weui_body qyzl">
              <div class="weui_cell">
                <div class="weui_left">公司名称：</div>
                <div class="weui_right">{{ qyxx.qymc }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">联系人：</div>
                <div class="weui_right">{{ qyxx.lxr }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">联系电话：</div>
                <div class="weui_right">{{ qyxx.lxrdh }}</div>
              </div>
              <div class="weui_cell">
                <div class="weui_left">联系地址：</div>
                <div class="weui_right">{{ qyxx.addr }}</div>
              </div>
            </div>
            <el-divider></el-divider>
            <div class="weui_media_desc">
              <p>企业详细介绍</p>
              <span @click="viewBtn({ name: '企业介绍', type: 'qyxx', bs: 'qyjs' })">查看 ></span>
            </div>

            <el-divider></el-divider>
            <el-divider></el-divider>

            <div class="weui_media_desc">
              <p>企业资质证书</p>
              <span @click="viewBtn({ name: '企业资质', type: 'qyxx', bs: 'qyzz' })">查看 ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import * as echarts from 'echarts';
// import videojs from 'video.js';
// import 'video.js/dist/video-js.css';
// import 'videojs-contrib-hls';
import {getPage} from '@/request/api/h5.js';

export default {
  data() {
    return {
      paramObj: {},
      pageData: {},
      imgUrl: ''
    };
  },
  computed: {
    csgl() {
      return this.pageData?.csgl || {};
    },
    qyxx() {
      return this.pageData?.qyxx || {};
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let uuid = this.$route.query.uuid
      getPage('/pro10/csgl/detail/' + uuid).then((res) => {
        if (res.status == 200) {
          if (res.data.state == 'success') {
            this.pageData = res.data.data;
            let url = this.pageData.csgl.cpimg ? JSON.parse(this.pageData.csgl.cpimg) : '';
            this.imgUrl = "http://public.half-half.cn/" + url;
          }
        }
      });
    },
    viewBtn(data) {
      data.params = JSON.stringify(this.pageData[data.type]);

      this.$router.push({path: '/viewPage', query: data});
    },
  }
};
</script>
<style scoped lang="less">
* {
  padding: 0;
  margin: 0;
}
.heaberColor {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background-color: #fdf9fb;
}

.heaber .el-page-header {
  font-family: Regular;
  color: #fff;
}

.home {
  .bimg {
    width: 100%;
    height: 100vh;
    background-image: url(~@/assets/H5/bg.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-attachment: scroll;

    .hd {
      padding-top: 3vh;
      font-size: 0.3rem;

      .img-circle {
        border-radius: 50%;
        padding: 2vw 0;
        // object-fit: cover;
        width: 20vw;
        //height: 50vw;
      }
    }

    .container-fluid {
      margin: 0 3vw;

      .weui_panel {
        background-color: #fff;
        padding: 6vw 2vw;
        margin-bottom: 3vw;
        font-size: 0.4rem;

        .weui_title {
          display: flex;
          align-items: center;
          margin-bottom: 2vw;

          img {
            margin-right: 1vw;
          }
        }

        .weui_body {
          .weui_cell {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #999999;
            margin: 3vw 0;
          }

          .weui_right {
            color: #000;
          }
        }

        .weui_foot {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.3rem;
          margin-top: 5vw;

          p {
            color: #999999;
            font-size: 16px;
            line-height: 1.2;
          }

          span {
            font-size: 16px;
            line-height: 1.2;
            margin-left: 1vw;
            color: green;
          }
        }
      }
    }
  }
}

.qyzl {
  font-size: 0.3rem;
}

.weui_media_desc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4vw 0;
  color: green;
  font-size: 0.4rem;
}

.strongCol {
  color: green !important;
  font-weight: 900;
}
</style>
